import './tagline.css'

export default function index() {
    return (
        <header id="tagline-header">
            <p className='first-header'>The Smarter Way to Connect, Network, and Close Deals in Real Estate.</p>
            <p className='second-header'>The Smarter Way to Connect, Network, and Close Deals in Real Estate.</p>
            <p className='last-header'>The Smarter Way to Connect, Network, and Close Deals in Real Estate.</p>
        </header >
    )
}
