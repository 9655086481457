import React from 'react';
import { useNavigate } from 'react-router-dom';

const Questionaire = () => {

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(event.target);
        navigate('/');
    };

    return (
        <div className=" py-5" style={{ backgroundColor: 'var(--background_color)' }}>
            <div className="card shadow-sm mx-auto" style={{ maxWidth: '800px' }}>
                <h1 className="text-center fw-bold my-4" style={{ color: 'var(--color)' }} > User Profiling</h1>
                <div className="card-body p-4">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label" style={{ color: 'var(--color)' }}>Primary Flipping Goals:</label>
                            <div>
                                <div className="form-check d-block">
                                    <input type="radio" name="goal" value="Quick Resale for Profit" className="form-check-input" id="goal1" />
                                    <label className="form-check-label" htmlFor="goal1">Quick Resale for Profit</label>
                                </div>
                                <div className="form-check d-block">
                                    <input type="radio" name="goal" value="Renovation and Appreciation" className="form-check-input" id="goal2" />
                                    <label className="form-check-label" htmlFor="goal2">Renovation and Appreciation</label>
                                </div>
                                <div className="form-check d-block">
                                    <input type="radio" name="goal" value="Adding Value through Improvements" className="form-check-input" id="goal3" />
                                    <label className="form-check-label" htmlFor="goal3">Adding Value through Improvements</label>
                                </div>
                                <div className="form-check d-block">
                                    <input type="radio" name="goal" value="Other" className="form-check-input" id="goal4" />
                                    <label className="form-check-label" htmlFor="goal4">Other (Please specify)</label>
                                </div>
                                <input type="text" className="form-control mt-2" placeholder="Specify other goal" />
                            </div>
                        </div>

                        <div className="mb-3">
                            <label className="form-label" style={{ color: 'var(--color)' }}>Preferred Property Types:</label>
                            <div>
                                <div className="form-check d-block">
                                    <input type="radio" name="property_type" value="Residential" className="form-check-input" id="propertyType1" />
                                    <label className="form-check-label" htmlFor="propertyType1">Residential</label>
                                </div>
                                <div className="form-check d-block">
                                    <input type="radio" name="property_type" value="Commercial" className="form-check-input" id="propertyType2" />
                                    <label className="form-check-label" htmlFor="propertyType2">Commercial</label>
                                </div>
                                <div className="form-check d-block">
                                    <input type="radio" name="property_type" value="Mixed-Use" className="form-check-input" id="propertyType3" />
                                    <label className="form-check-label" htmlFor="propertyType3">Mixed-Use</label>
                                </div>
                                <div className="form-check d-block">
                                    <input type="radio" name="property_type" value="Land" className="form-check-input" id="propertyType4" />
                                    <label className="form-check-label" htmlFor="propertyType4">Land</label>
                                </div>
                                <div className="form-check d-block">
                                    <input type="radio" name="property_type" value="Industrial" className="form-check-input" id="propertyType5" />
                                    <label className="form-check-label" htmlFor="propertyType5">Industrial</label>
                                </div>
                                <div className="form-check d-block">
                                    <input type="radio" name="property_type" value="Other" className="form-check-input" id="propertyType6" />
                                    <label className="form-check-label" htmlFor="propertyType6">Other (Please specify)</label>
                                </div>
                                <input type="text" className="form-control mt-2" placeholder="Specify other property type" />
                            </div>
                        </div>

                        <div className="mb-3">
                            <label className="form-label" style={{ color: 'var(--color)' }}>Residential Property Preferences:</label>
                            <div>
                                <div className="form-check d-block">
                                    <input type="radio" name="residential_type" value="Single-Family Homes" className="form-check-input" id="residentialType1" />
                                    <label className="form-check-label" htmlFor="residentialType1">Single-Family Homes</label>
                                </div>
                                <div className="form-check d-block">
                                    <input type="radio" name="residential_type" value="Multi-Family Units" className="form-check-input" id="residentialType2" />
                                    <label className="form-check-label" htmlFor="residentialType2">Multi-Family Units</label>
                                </div>
                                <div className="form-check d-block">
                                    <input type="radio" name="residential_type" value="Condominiums" className="form-check-input" id="residentialType3" />
                                    <label className="form-check-label" htmlFor="residentialType3">Condominiums</label>
                                </div>
                                <div className="form-check d-block">
                                    <input type="radio" name="residential_type" value="Townhouses" className="form-check-input" id="residentialType4" />
                                    <label className="form-check-label" htmlFor="residentialType4">Townhouses</label>
                                </div>
                                <div className="form-check d-block">
                                    <input type="radio" name="residential_type" value="Other" className="form-check-input" id="residentialType5" />
                                    <label className="form-check-label" htmlFor="residentialType5">Other (Please specify)</label>
                                </div>
                                <div className="form-check d-block">
                                    <input type="radio" name="residential_type" value="No preference" className="form-check-input" id="residentialType6" />
                                    <label className="form-check-label" htmlFor="residentialType6">No preference</label>
                                </div>
                                <input type="text" className="form-control mt-2" placeholder="Specify other residential property" />
                            </div>
                        </div>

                        <div className="mb-3">
                            <label className="form-label" style={{ color: 'var(--color)' }}>Commercial Property Preferences:</label>
                            <div>
                                <div className="form-check d-block">
                                    <input type="radio" name="commercial_type" value="Retail Spaces" className="form-check-input" id="commercialType1" />
                                    <label className="form-check-label" htmlFor="commercialType1">Retail Spaces</label>
                                </div>
                                <div className="form-check d-block">
                                    <input type="radio" name="commercial_type" value="Office Buildings" className="form-check-input" id="commercialType2" />
                                    <label className="form-check-label" htmlFor="commercialType2">Office Buildings</label>
                                </div>
                                <div className="form-check d-block">
                                    <input type="radio" name="commercial_type" value="Warehouses" className="form-check-input" id="commercialType3" />
                                    <label className="form-check-label" htmlFor="commercialType3">Warehouses</label>
                                </div>
                                <div className="form-check d-block">
                                    <input type="radio" name="commercial_type" value="Other" className="form-check-input" id="commercialType4" />
                                    <label className="form-check-label" htmlFor="commercialType4">Other (Please specify)</label>
                                </div>
                                <div className="form-check d-block">
                                    <input type="radio" name="commercial_type" value="No preference" className="form-check-input" id="commercialType5" />
                                    <label className="form-check-label" htmlFor="commercialType5">No preference</label>
                                </div>
                                <input type="text" className="form-control mt-2" placeholder="Specify other commercial property" />
                            </div>
                        </div>
                        <button className="btn btn-primary w-100 py-3">Submit Form</button>

                    </form>
                </div>
            </div>
        </div >
    );
};

export default Questionaire;

