import React from "react";
import ProcessStep from "../../components/StepCard";
import { FaLightbulb, FaUsers, FaRegHandshake } from "react-icons/fa";

const MissionVision = () => {
    const data = [
        {
            title: "Our Mission",
            description:
                "To simplify real estate networking and transactions by providing tailored connections, actionable insights, and a platform that brings together every professional in the industry under one digital roof.",
            icon: <FaRegHandshake size={24} />,
            bgColor: "bg-success",
        },
        {
            title: "Our Vision",
            description:
                "To become the leading hub for real estate professionals worldwide, fostering a collaborative ecosystem that transforms how people buy, sell, and invest in properties. At ZNet, we believe in building a future where opportunities are just one connection away.",
            icon: <FaLightbulb size={24} />,
            bgColor: "bg-warning",
        },
    ];

    return (
        <section className="py-5 bg-light">
            <div className="container">
                <h2 className="section-title text-center mb-4">About Us</h2>
                <div className="row g-4">
                    {data.map((item, index) => (
                        <ProcessStep
                            key={index}
                            title={item.title}
                            description={item.description}
                            icon={item.icon}
                            bgColor={item.bgColor}
                            textColor="text-white"
                            cardsCount={2}
                            pos="center"
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default MissionVision;

