import React, { useState } from "react";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './HousingSubscriptionForm.css';
import axios from "axios";

const HousingSubscriptionForm = () => {
    const [email, setEmail] = useState("");
    const [getEmails, setGetEmails] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("https://api.biznetusa.com/api/store-email-alerts", {
                email,
                get_emails: getEmails,
            });
            if (response.status === 200) {
                toast.success("You have successfully subscribed to email alerts!");
                setEmail("");
                setGetEmails(false);
            } else {
                toast.error("Failed to subscribe. Please try again later.");
            }
        } catch (error) {
            toast.error("Failed to subscribe. Please try again later.");
        }
    };

    return (
        <Container fluid className="subscription-section py-5 bg-light">
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
            <Row className="justify-content-center align-items-center p-4">
                <Col lg={6} md={8} sm={12} className="text-center">
                    <h2 className="mb-4">Stay Updated with the Latest Listings</h2>
                    <p className="text-muted mb-4">
                        Subscribe to receive the latest housing news, tips, and exclusive offers directly in your inbox.
                    </p>
                    <Form onSubmit={handleSubmit} className="d-flex flex-column align-items-center gap-3">
                        <InputGroup className="w-100">
                            <Form.Control
                                type="email"
                                placeholder="Enter your email address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="rounded-pill shadow-sm"
                            />
                            <Button variant="primary" type="submit" className="rounded-pill shadow-sm px-4">
                                Subscribe
                            </Button>
                        </InputGroup>
                        <Form.Check
                            type="checkbox"
                            label="I agree to receive email alerts."
                            checked={getEmails}
                            onChange={(e) => setGetEmails(e.target.checked)}
                            className="text-muted"
                        />
                    </Form>
                    <small className="text-muted d-block mt-3">
                        By subscribing, you agree to Znet’s <a href="#" className="text-decoration-none">Terms of Use</a> and <a href="#" className="text-decoration-none">Privacy Policy</a>.
                    </small>
                </Col>
            </Row>
        </Container>
    );
};

export default HousingSubscriptionForm;
