import React from "react";
import "./Advertise.css";
import Header from "../../components/header";
import Footer from "../../components/Footer";
import FAQs from "../../components/FAQs";
import PlanCard from "../../components/PlanCard";

const Advertise = () => {
    const FAQData = [
        {
            question: 'Do you store any of my information?',
            answer: 'We only store information necessary for your account management and service improvement.',
        },
        {
            question: 'How can we help?',
            answer: 'For assistance, contact our support team via the contact form or email provided on our website.',
        },
        {
            question: 'How do I delete my account?',
            answer: 'To delete your account, go to account settings and select "Delete Account." For help, contact support.',
        },
        {
            question: 'How can I reset my password?',
            answer: 'You can reset your password using the "Forgot Password" link on the login page.',
        },
        {
            question: 'What payment methods do you accept?',
            answer: 'We accept major credit cards, PayPal, and other secure payment methods.',
        },

        {
            question: 'What is cloud backup?',
            answer: 'Cloud backup is a service where your data is stored and backed up on remote servers over the internet.',
        },
        {
            question: "I've got a problem, how do I contact support?",
            answer: 'You can contact support by submitting a ticket through our system or via email.',
        },
        {
            question: 'How do I delete my account?',
            answer: 'To delete your account, go to your account settings and choose "Delete Account." For assistance, contact support.',
        },
        {
            question: 'Can I change my subscription plan?',
            answer: 'Yes, you can change your subscription plan anytime from your account settings.',
        },
        {
            question: 'What should I do if I forgot my username?',
            answer: 'You can recover your username by checking your registered email for account details or by contacting support.',
        },
    ];
    const plans = [
        {
            title: "Starter Package",
            iconUrl: "https://openui.fly.dev/openui/24x24.svg?text=🚀",
            price: "PKR 14,000",
            subtitle: "Starting From",
            billingInfo: "Per Month (Billed Annually)",
            features: [
                "Upto 50 Listings",
                "Upto 10 Super Hot Credits",
                "Upto 80 Hot Credits",
                "Upto 800 Refresh Credits",
                "24/7 Normal Support",
            ],
        },
        {
            title: "Business Package",
            iconUrl: "https://openui.fly.dev/openui/24x24.svg?text=📦",
            price: "PKR 55,000",
            subtitle: "Starting From",
            billingInfo: "Per Month (Billed Annually)",
            features: [
                "Upto 150 Listings",
                "Upto 60 Super Hot Credits",
                "Upto 360 Hot Credits",
                "Upto 6000 Refresh Credits",
                "1 Type of Agency Banner",
                "24/7 Normal Support",
            ],
        },
        {
            title: "Titanium Plus",
            iconUrl: "https://openui.fly.dev/openui/24x24.svg?text=💎",
            price: "PKR 333,000",
            subtitle: "Starting From",
            billingInfo: "Per Month (Billed Annually)",
            features: [
                "300 Listings",
                "84 Super Hot Credits",
                "600 Hot Credits",
                "10800 Refresh Credits",
                "Titanium Plus Badge",
                "Priority Listing Approval",
                "5 Types of Agency Banners",
                "Exclusive Agency Profile",
                "Video Interview",
                "24/7 Priority Support",
                "Featured Agency",
            ],
            mostPopular: true,
        },
        {
            title: "Titanium",
            iconUrl: "https://openui.fly.dev/openui/24x24.svg?text=💼",
            price: "PKR 200,000",
            subtitle: "Starting From",
            billingInfo: "Per Month (Billed Annually)",
            features: [
                "200 Listings",
                "60 Super Hot Credits",
                "360 Hot Credits",
                "7200 Refresh Credits",
                "Titanium Badge",
                "Priority Listing Approval",
                "3 Types of Agency Banners",
                "24/7 Normal Support",
            ],
        },
    ];

    return (
        <>
            <Header />
            <div className="parent-advertisement">
                <div className="hero-parent">
                    <section className="hero-section d-flex align-items-center justify-content-center">
                        <div className="bg-background p-5 rounded-lg shadow-lg text-center">
                            <h1 className="display-4 font-weight-bold mb-4">
                                Let <span className="text-accent">Znet</span> Elevate Your Real
                                Estate Journey
                            </h1>
                            <p className="lead mb-6">
                                Succeed in the competitive real estate market in world with Znet's
                                reliable platform, expert guidance, and access to a diverse audience
                                of potential buyers and sellers.
                            </p>
                            <div className="d-flex justify-content-center div-btn-advertise gap-3">
                                <button className="btn btn-light p-3 rounded-lg d-flex align-items-center">
                                    <img
                                        className="img-advertise"
                                        aria-hidden="true"
                                        alt="individual-icon"
                                        src="https://openui.fly.dev/openui/24x24.svg?text=👤"
                                    />
                                    For Individuals
                                </button>
                                <button className="btn btn-light p-3 rounded-lg d-flex align-items-center">
                                    <img
                                        className="img-advertise"
                                        aria-hidden="true"
                                        alt="agency-icon"
                                        src="https://openui.fly.dev/openui/24x24.svg?text=📣"
                                    />
                                    For Agencies
                                </button>
                                <button className="btn btn-light p-3 rounded-lg d-flex align-items-center">
                                    <img
                                        className="img-advertise"
                                        aria-hidden="true"
                                        alt="developer-icon"
                                        src="https://openui.fly.dev/openui/24x24.svg?text=💼"
                                    />
                                    For Developers
                                </button>
                            </div>
                        </div>
                    </section>
                </div>

                {/* section dvertise for individual */}
                <div className="container-fluid p-5 my-5">
                    <h2 className="text-center mb-4">For Individual</h2>
                    <div className="row g-4 justify-content-center">
                        {/* Listing Slot Card */}
                        <div className="col-md-3 col-sm-6">
                            <div className="custom-card">
                                <div className="custom-card-body">
                                    <img
                                        src="/assets/images/listing.webp"
                                        alt="Listing Slot Icon"
                                        className="custom-card-icon"
                                    />
                                    <h5 className="custom-card-title">Listing Slot</h5>
                                    <p className="custom-card-text">
                                        Get an ad slot for 30 days to publish
                                    </p>
                                    <div className="custom-price">PKR 3000/month</div>
                                    <a href="#" className="btn custom-btn-card btn-success w-50">
                                        Buy Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Hot Listing Card */}
                        <div className="col-md-3 col-sm-6">
                            <div className="custom-card">
                                <div className="custom-card-body">
                                    <img
                                        src="https://via.placeholder.com/50"
                                        alt="Hot Listing Icon"
                                        className="custom-card-icon"
                                    />
                                    <h5 className="custom-card-title">Hot Listing</h5>
                                    <p className="custom-card-text">
                                        Property ranks above basic listings with 15x exposure
                                    </p>
                                    <div className="custom-price">PKR 7800/month</div>
                                    <a href="#" className="btn custom-btn-card btn-success w-50">
                                        Buy Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Super Hot Listing Card */}
                        <div className="col-md-3 col-sm-6">
                            <div className="custom-card">
                                <div className="custom-card-body">
                                    <img
                                        src="https://via.placeholder.com/50"
                                        alt="Super Hot Listing Icon"
                                        className="custom-card-icon"
                                    />
                                    <h5 className="custom-card-title">Super Hot Listing</h5>
                                    <p className="custom-card-text">
                                        Property ranks at the top with 25x exposure
                                    </p>
                                    <div className="custom-price">PKR 21000/month</div>
                                    <a href="#" className="btn custom-btn-card btn-success w-50">
                                        Buy Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Refresh Credits Card */}
                        <div className="col-md-3 col-sm-6">
                            <div className="custom-card">
                                <div className="custom-card-body">
                                    <img
                                        src="https://via.placeholder.com/50"
                                        alt="Refresh Credits Icon"
                                        className="custom-card-icon"
                                    />
                                    <h5 className="custom-card-title">Refresh Credits</h5>
                                    <p className="custom-card-text">
                                        Refresh the time of your posted listings and bring them
                                    </p>
                                    <div className="custom-price">PKR 240/credits</div>
                                    <a href="#" className="btn custom-btn-card btn-success w-50">
                                        Buy Now
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Story Ads Credits Card */}
                    </div>
                </div>
                {/* new section */}
                <div className="container my-5">
                    <h2 className="text-center mb-4">For Agencies</h2>
                    <div className="row justify-content-center">
                        {plans.map((plan, index) => (
                            <PlanCard key={index} {...plan} />
                        ))}
                    </div>
                </div>
                {/* For Developers */}
                <div className="container my-5">
                    <h2 className="text-center mb-4">For Developers</h2>
                    <div className="row justify-content-center">
                        {plans.map((plan, index) => (
                            <PlanCard key={index} {...plan} />
                        ))}
                    </div>
                </div>
                {/* testimonial */}
                <div >
                    <h2 className="text-center">Frequently Asked Questions</h2>
                    <FAQs faqs={FAQData} />
                </div>

            </div>
            <Footer />

        </>
    );

}
export default Advertise;
