import React from 'react';
import './GetReferrals.css';
import Header from '../../components/header';
import Footer from '../../components/Footer';
import ImageWithText from '../../components/ImagewithText';
import img01 from '../../assets/images/why biznet 02.jpg';
import img02 from '../../assets/images/why biznet 01.jpg';
import img03 from '../../assets/images/why biznet 03.jpg';

const GetReferrals = () => {
    return (
        <>
            <Header />
            <div className="referrals-container">
                {/* Hero Section */}
                <section className="hero-section text-center py-5 bg-primary text-white">
                    <div className="container">
                        <h1 className="display-4 fw-bold mb-3">Earn While You Connect</h1>
                        <p className="lead mb-4">Get rewarded for referring clients to our trusted network of real estate professionals</p>
                        <div className="stats-row row justify-content-center text-center mt-5">
                            <div className="col-md-4">
                                <h2 className="display-3 fw-bold">$500+</h2>
                                <p>Average Referral Bonus</p>
                            </div>
                            <div className="col-md-4">
                                <h2 className="display-3 fw-bold">24h</h2>
                                <p>Quick Response Time</p>
                            </div>
                            <div className="col-md-4">
                                <h2 className="display-3 fw-bold">1000+</h2>
                                <p>Successful Referrals</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* How It Works Section */}
                <section className="how-it-works py-5">
                    <div className="container">
                        <h2 className="text-center mb-5">How Our Referral Program Works</h2>
                        <div className="row g-4">
                            <div className="col-md-3">
                                <div className="process-card h-100 text-center p-4 shadow-sm rounded">
                                    <div className="process-icon mb-3">
                                        <i className="fas fa-user-plus fa-3x text-primary"></i>
                                    </div>
                                    <h3 className="h5">1. Submit Referral</h3>
                                    <p>Fill out our simple referral form with your contact's information</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="process-card h-100 text-center p-4 shadow-sm rounded">
                                    <div className="process-icon mb-3">
                                        <i className="fas fa-phone fa-3x text-primary"></i>
                                    </div>
                                    <h3 className="h5">2. We Make Contact</h3>
                                    <p>Our team reaches out to your referral within 24 hours</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="process-card h-100 text-center p-4 shadow-sm rounded">
                                    <div className="process-icon mb-3">
                                        <i className="fas fa-handshake fa-3x text-primary"></i>
                                    </div>
                                    <h3 className="h5">3. Deal Closes</h3>
                                    <p>When your referral completes a transaction</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="process-card h-100 text-center p-4 shadow-sm rounded">
                                    <div className="process-icon mb-3">
                                        <i className="fas fa-dollar-sign fa-3x text-primary"></i>
                                    </div>
                                    <h3 className="h5">4. Get Paid</h3>
                                    <p>Receive your referral bonus within 30 days of closing</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                {/* Benefits Section */}
                <section className="benefits-section py-5">
                    <div className="container">
                        <h2 className="text-center mb-5">Why Refer Through Us?</h2>
                        <div className="row g-4">
                            <div className="col-md-4">
                                <div className="benefit-card text-center p-4">
                                    <i className="fas fa-percentage fa-2x text-primary mb-3"></i>
                                    <h3 className="h5">Competitive Commissions</h3>
                                    <p>Earn up to 25% of the agent's commission for each successful referral</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="benefit-card text-center p-4">
                                    <i className="fas fa-shield-alt fa-2x text-primary mb-3"></i>
                                    <h3 className="h5">Guaranteed Service</h3>
                                    <p>Your referrals are matched with top-performing agents in our network</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="benefit-card text-center p-4">
                                    <i className="fas fa-chart-line fa-2x text-primary mb-3"></i>
                                    <h3 className="h5">Track Progress</h3>
                                    <p>Monitor your referrals and earnings through our dashboard</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="container">
                <ImageWithText
                    content="Join our network of successful referral partners and start earning today. Our streamlined process makes it easy to refer clients and track your earnings. With competitive commission rates and a dedicated support team, you'll have everything you need to build a profitable referral business."
                    imgSrc={img01}
                    altText="Business networking and referral partners"
                    title="Start Your Referral Journey Today"
                    imagePosition="left"
                />
                <ImageWithText
                    content="Join thousands of successful partners who have transformed their network into profitable opportunities. Our partners consistently earn substantial referral bonuses while helping friends and colleagues find their dream homes. Your connections could be worth more than you think."
                    imgSrc={img02}
                    altText="Successful referral partnerships"
                    title="Turn Your Network Into Opportunity"
                    imagePosition="right"
                />
                <ImageWithText
                    content="Experience hassle-free referrals with our cutting-edge platform. Track your referrals in real-time, receive instant notifications on progress, and get paid promptly upon successful closings. Our transparent process ensures you're always in the loop while your referrals turn into rewards."
                    imgSrc={img03}
                    altText="Digital referral tracking platform"
                    title="Seamless Tracking & Rewards"
                    imagePosition="left"
                />
            </div>
            <Footer />
        </>
    );
};

export default GetReferrals;
