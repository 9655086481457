import { FaHardHat, FaMoneyBillWave, FaUser } from "react-icons/fa";
import { MdHandyman, MdOutlineSupportAgent } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function PurposeSection() {
    const navigate = useNavigate();

    return (
        <section className="container py-5">
            <h3 className="text-center fw-bold mb-3" style={{ fontSize: "2.5rem" }}>
                Connecting Real Estate Professionals Like Never Before
            </h3>
            <p className="text-center fs-5 text-muted mb-5">
                ZNet simplifies real estate networking by instantly matching you with the right people based on your goals.
                Whether you’re buying, selling, or investing, ZNet makes it easy to connect, collaborate, and succeed.
            </p>

            <div className="btn-grp d-flex justify-content-center mb-5 gap-3">
                <button className="btn btn-primary" onClick={() => navigate('/SignUp')}>Sign Up</button>
                <button className="btn btn-secondary" onClick={() => navigate('/About')}>Learn More</button>
            </div>

            <div className="d-flex justify-content-center gap-5 align-items-center flex-wrap">
                <div className="text-center">
                    <div
                        className="icon-container bg-primary text-white rounded-circle d-flex justify-content-center align-items-center mx-auto"
                        style={{ width: "80px", height: "80px" }}>
                        <FaUser size={35} />
                    </div>
                    <p className="mt-3 fw-semibold">Contractors</p>
                </div>
                <div className="text-center">
                    <div
                        className="icon-container bg-warning text-white rounded-circle d-flex justify-content-center align-items-center mx-auto"
                        style={{ width: "80px", height: "80px" }}>
                        <FaHardHat size={35} />
                    </div>
                    <p className="mt-3 fw-semibold">Contractors</p>
                </div>
                <div className="text-center">
                    <div
                        className="icon-container bg-secondary text-white rounded-circle d-flex justify-content-center align-items-center mx-auto"
                        style={{ width: "80px", height: "80px" }}>
                        <MdHandyman size={35} />
                    </div>
                    <p className="mt-3 fw-semibold">Resellers</p>
                </div>
                <div className="text-center">
                    <div
                        className="icon-container bg-success text-white rounded-circle d-flex justify-content-center align-items-center mx-auto"
                        style={{ width: "80px", height: "80px" }}>
                        <FaMoneyBillWave size={35} />
                    </div>
                    <p className="mt-3 fw-semibold">Investors</p>
                </div>
                <div className="text-center">
                    <div
                        className="icon-container bg-danger text-white rounded-circle d-flex justify-content-center align-items-center mx-auto"
                        style={{ width: "80px", height: "80px" }}>
                        <MdOutlineSupportAgent size={35} />
                    </div>
                    <p className="mt-3 fw-semibold">Agents</p>
                </div>
            </div>
        </section>
    );
}

export default PurposeSection;

