import React, { useEffect, useId, useState } from "react";
import MainCards from "../../components/MainCards";
import HomeBannerCard from "../../components/HomeBannerCard";
import img1 from '../../assets/images/real estate _1.jpg';
import img2 from '../../assets/images/real estate _10.jpg';
import img3 from '../../assets/images/real estate _11.jpg';
import CityRealEstateList from "../../components/CityRealEstateList";
import RealEstateTabs from "../../components/RealEstateTabs";
import RentByCityList from "../../components/RentByCityList"
import Footer from "../../components/Footer";
import Header from "../../components/header";
import TaglineHeader from '../../components/TaglineHeader'
import Testimonial from "../../components/Testimonial";
import PurposeSection from "../../components/PurposeSection";
import ProcessSection from "../../components/ProcessSection";
import WhyZnet from "../../components/WhyZnet";
import AppartementsRealEstate from "../../components/AppartementsRealEstate";
import GetLocation from '../../components/ProductByLocation'
import UserProfiling from "../../components/UserProfiling";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import Recommendations from "../../components/Recommandation";



const Home = () => {
    const propertyImages = [img1, img2, img3];
    const [showModal, setShowModal] = useState(false);
    const [userRole, setUserRole] = useState("Guest");
    const [userId, setUserId] = useState(null)

    // Fetch user role from localStorage
    const fetchUserRole = () => {
        const storedUserRole = localStorage.getItem("roles");
        setUserRole(storedUserRole || "Guest"); // Set 'Guest' if no role is found
    };

    const fetchUserId = () => {
        const storedUserId = localStorage.getItem("user_id");
        setUserId(storedUserId || null);
    }

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(`https://api.biznetusa.com/api/get-userprofiling/${userId}`);
                setShowModal(response.data.user_profiling.length === 0)
                console.log(response.data);
            } catch (err) {
                console.error(err)
            }
        }
        if (userId) {
            fetchUserProfile();
        }
    }, [userId]);

    useEffect(() => {
        fetchUserRole();
        fetchUserId();

        const handleStorageChange = (event) => {
            if (event.key === "roles") {
                fetchUserRole();
            }
        };

        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    return (
        <>
            <UserProfiling
                userRole={userRole}
                showModal={showModal}
                setShowModal={setShowModal}
            />
            <ToastContainer />
            <TaglineHeader />
            <Header />
            <main className="Main_portion">
                {userId ? (<div className="container-fluid px-4 pb-4 g-0 rounded bg-light">
                    <div className="row">
                        <RealEstateTabs />
                        <HomeBannerCard
                            images={propertyImages}
                            location="Chevy Chase, MD"
                            price="$3,599,000"
                            title="Znet PREMIER LISTING"
                        />
                    </div>
                </div>) : (<HomeBannerCard isLarge={true} />)}
                {userId ?
                    (
                        <>
                            <MainCards />
                            <GetLocation />
                        </>
                    ) : (
                        <Recommendations />
                    )
                }
                {!userId && (
                    <>
                        <PurposeSection />
                        <ProcessSection />
                        <WhyZnet />
                        <Testimonial />
                    </>)}

                <div className="container py-4">
                    <h3 className="m-3">Search for homes by city</h3>
                    <CityRealEstateList />
                    <hr className="my-5" />
                    <h3 className="m-3">Search for apartments by city</h3>
                    <AppartementsRealEstate />
                    <hr className="my-5" />
                    <h3 className="m-3">Search for houses for rent by city</h3>
                    <RentByCityList />
                </div>

            </main>
            <Footer />
        </>
    );
};

export default Home;

