import React from "react";
import { FaRegHandshake } from "react-icons/fa";

const ProcessStep = ({
    icon,
    title,
    description,
    cardsCount = 4,
    pos = "center",
    bgColor = "bg-primary",
    textColor = "text-white"
}) => {
    const colSize = Math.floor(12 / cardsCount);

    return (
        <div className={`col-md-${colSize}`} style={{ height: '100% !important' }}>
            <div className={`process-step p-4 rounded shadow d-flex flex-column align-items-${pos} text-${pos}`} style={{ height: '100%' }}>
                <div className="process-icon mb-3">
                    <div
                        className={`text-white rounded-circle d-flex justify-content-center align-items-center mx-auto ${bgColor}`}
                        style={{ width: "50px", height: "50px" }}
                    >
                        {icon || <FaRegHandshake size={24} className={textColor} />}
                    </div>
                </div>
                <h5 className="fw-bold mb-2">{title}</h5>
                <p className="text-muted p-0 m-0">{description}</p>
            </div>
        </div >
    );
};

export default ProcessStep;

