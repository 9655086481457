import React from 'react';
import img1 from "../../assets/images/sell_6.png";
import img2 from "../../assets/images/sell_5.png";
import img3 from "../../assets/images/sell_4.png";

const SellersCard = ({ title }) => {
    const cards = [
        {
            imgSrc: img1, // Use the image variable directly, without curly braces
            altText: 'Best agents in your market',
            heading: 'We have the best agents in your market',
            description: 'Znet agents are among the most experienced in the industry.'
        },
        {
            imgSrc: img2, // Use the image variable directly, without curly braces
            altText: 'Reach more buyers',
            heading: 'Reach more buyers',
            description: 'Znet is the #1 brokerage website, with five times more traffic than the next closest competitor.'
        },
        {
            imgSrc: img3, // Use the image variable directly, without curly braces
            altText: 'Save with a low listing fee',
            heading: 'Save with a listing fee as low as 1%',
            description: 'When you buy and sell with us, you’ll pay half the fee other brokerages often charge.'
        }
    ];

    return (
        <div className="row g-0 py-md-4 py-2 set_card_hover">
            <div>
                <h2 className="h2 fw-bold mb-4">{title}</h2>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    {cards.map((card, index) => (
                        <div className="col" key={index}>
                            <div className="card h-100 shadow-sm">
                                <img
                                    src={card.imgSrc} // Properly refer to the image path
                                    width="100px"
                                    alt={card.altText}
                                    className="rounded mb-4 mx-auto"
                                />
                                <div className="card-body">
                                    <h3 className="h6 fw-semibold">{card.heading}</h3>
                                    <p>{card.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SellersCard;
