import React, { useEffect, useState } from "react";
import { Accordion, Container, Row, Col, Card } from "react-bootstrap";
import Header from "../../../components/header";
import Footer from "../../../components/Footer";

const FAQsPage = () => {
    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        const fetchFAQs = async () => {
            try {
                const response = await fetch("https://api.biznetusa.com/api/all-mainfaqs");
                const data = await response.json();
                if (data.status === 200) {
                    setFaqs(data.mainfaqs);
                }
            } catch (error) {
                console.error("Error fetching FAQs:", error);
            }
        };

        fetchFAQs();
    }, []);

    return (
        <>
            <Header />
            <main>
                <Container className="my-5">
                    <h2 className="text-center mb-4">Frequently Asked Questions</h2>
                    <Row>
                        <Col lg={8} md={12} className="mx-auto">
                            <Accordion defaultActiveKey={0}>
                                {faqs.map((faq, index) => (
                                    <Accordion.Item eventKey={index} key={faq.id}>
                                        <Accordion.Header>{faq.title}</Accordion.Header>
                                        <Accordion.Body>{faq.desc}</Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Col>
                    </Row>
                </Container>

                <Container className="my-5">
                    <Row className="align-items-center">
                        <Col lg={5} md={12} className="mb-4">
                            <h2>What Clients Say About Us</h2>
                            <p>
                                We help clients buy and sell homes, yes, but it's about far more than
                                closing deals for us.
                            </p>
                            <a href="#" className="btn btn-outline-primary">
                                Explore All →
                            </a>
                        </Col>
                        <Col lg={7} md={12}>
                            <div
                                id="testimonialCarousel"
                                className="carousel slide"
                                data-bs-ride="carousel"
                                data-bs-interval={5000}
                            >
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <Card className="p-4">
                                            <p>
                                                Amazing customer support so far, working well with a theme that's so
                                                easily customizable and great to use. Thanks and 5 stars!
                                            </p>
                                            <div className="d-flex align-items-center">
                                                <img
                                                    src="https://randomuser.me/api/portraits/women/65.jpg"
                                                    alt="Maggie Strickland"
                                                    className="rounded-circle me-3"
                                                    style={{ width: 50 }}
                                                />
                                                <div>
                                                    <strong>Maggie Strickland</strong>
                                                    <p className="mb-0 text-muted">Manchester</p>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="carousel-item">
                                        <Card className="p-4">
                                            <p>
                                                Working with @GrandHome is like having a family member who can fix
                                                everything. They know what you need, exactly when you need it.
                                            </p>
                                            <div className="d-flex align-items-center">
                                                <img
                                                    src="https://randomuser.me/api/portraits/women/66.jpg"
                                                    alt="Dollie Horton"
                                                    className="rounded-circle me-3"
                                                    style={{ width: 50 }}
                                                />
                                                <div>
                                                    <strong>Dollie Horton</strong>
                                                    <p className="mb-0 text-muted">San Diego</p>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                    <div className="carousel-item">
                                        <Card className="p-4">
                                            <p>
                                                Very good and fast support. Solved all my problems within a week.
                                                Excited to see more themes they make!
                                            </p>
                                            <div className="d-flex align-items-center">
                                                <img
                                                    src="https://randomuser.me/api/portraits/men/65.jpg"
                                                    alt="Michael Johnson"
                                                    className="rounded-circle me-3"
                                                    style={{ width: 50 }}
                                                />
                                                <div>
                                                    <strong>Michael Johnson</strong>
                                                    <p className="mb-0 text-muted">Los Angeles</p>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                                <button
                                    className="carousel-control-prev"
                                    type="button"
                                    data-bs-target="#testimonialCarousel"
                                    data-bs-slide="prev"
                                >
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button
                                    className="carousel-control-next"
                                    type="button"
                                    data-bs-target="#testimonialCarousel"
                                    data-bs-slide="next"
                                >
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </main>
            <Footer />
        </>
    );
};

export default FAQsPage;