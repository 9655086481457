import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Card, Carousel, Button, Container } from "react-bootstrap";
import "./ProductAllCards.css";

const ProductAllCards = () => {
    const [products, setProducts] = useState([]);

    const location = useLocation();
    const cityId = new URLSearchParams(location.search).get("cityId");
    const appartmentId = new URLSearchParams(location.search).get("appartmentId");
    const rentCityId = new URLSearchParams(location.search).get("RentByCityId");
    const navigate = useNavigate();
    const fetchCalled = useRef(false);

    useEffect(() => {
        const fetchProducts = async () => {
            if (fetchCalled.current) return;
            fetchCalled.current = true;

            try {
                let response;
                if (cityId) {
                    response = await axios.get(`https://api.biznetusa.com/api/get-filterbyhome/${cityId}`);
                } else if (rentCityId) {
                    response = await axios.get(`https://api.biznetusa.com/api/get-filterbyrent/${rentCityId}`);
                } else if (appartmentId) {
                    response = await axios.get(`https://api.biznetusa.com/api/get-filterbyapartment/${appartmentId}`);
                } else {
                    console.error("No valid parameters to fetch data.");
                    return;
                }

                setProducts(response.data.products || []);
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };

        fetchProducts();
    }, [cityId, rentCityId, appartmentId]);

    const handleRequestTourClick = () => {
        navigate("/RequestShowing");
    };

    return (
        <Container fluid className="py-4 product-cards-container">
            <div className="cards-wrapper">
                {products.map((product, index) => (
                    <div className="custom-card-wrapper" key={product.id}>
                        <Card className="custom-card">
                            <Carousel>
                                {product.images && product.images.length > 0 ? (
                                    product.images.map((image, imgIndex) => (
                                        <Carousel.Item key={imgIndex}>
                                            <img
                                                onClick={() => navigate(`/ProductDetail/${product.id}`)}
                                                src={`https://api.biznetusa.com/uploads/products/${image.image}`}
                                                alt={`Property ${product.title} - Image ${imgIndex + 1}`}
                                                className="custom-card-img"
                                            />
                                        </Carousel.Item>
                                    ))
                                ) : (
                                    <Carousel.Item>
                                        <img
                                            src="https://via.placeholder.com/400x300?text=No+Image+Available"
                                            alt="No Image Available"
                                            className="custom-card-img"
                                        />
                                    </Carousel.Item>
                                )}
                            </Carousel>
                            <Card.Body>
                                <h5 className="fw-bold text-primary">${product.price}</h5>
                                <p className="text-muted">{product.location || "Location not available"}</p>
                                <p className="text-truncate">{product.desc || "No description available"}</p>
                                <Button
                                    variant="primary"
                                    className="custom-btn"
                                    onClick={handleRequestTourClick}
                                >
                                    Request a Tour
                                </Button>
                            </Card.Body>
                        </Card>
                    </div>
                ))}
            </div>
        </Container>
    );
};

export default ProductAllCards;
