import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { signInWithGoogle, signInWithFacebook, signInWithApple } from '../../authMethods';

const SignUp = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        role_id: "",
        password: "",
        answers: [], // Store answers as an array of objects
    });
    const [roles, setRoles] = useState([]);
    const [questions, setQuestions] = useState([]);
    const navigate = useNavigate();

    const handleSocialLogin = (loginFunction) => {
        loginFunction()
            .then((user) => {
                console.log("Logged in user:", user);
                alert("Login successful!");
                navigate("/login"); // Navigate after successful login
            })
            .catch((error) => {
                console.error("Login Error:", error);
                alert(`Login failed: ${error.message}`);
            });
    };

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await fetch("https://api.biznetusa.com/api/get-roles");
                const data = await response.json();
                if (response.ok) {
                    setRoles(data.roles || []);
                } else {
                    throw new Error("Failed to fetch roles");
                }
            } catch (error) {
                console.error("Error fetching roles:", error);
                toast.error("Unable to fetch roles.");
            }
        };
        fetchRoles();
    }, []);

    const fetchQuestions = async (roleName) => {
        try {
            const response = await fetch(`https://api.biznetusa.com/api/get-questions/${roleName}`);
            const data = await response.json();
            if (response.ok) {
                setQuestions(data.questions || []);
            } else {
                throw new Error("Failed to fetch questions");
            }
        } catch (error) {
            console.error("Error fetching questions:", error);
            toast.error("Unable to fetch questions. Please try again later.");
        }
    };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value,
        }));

        if (id === "role_id") {
            const selectedRole = roles.find((role) => role.id === parseInt(value));
            if (selectedRole) {
                fetchQuestions(selectedRole.role_name);
            }
        }
    };

    const handleAnswerChange = (questionId, answer) => {
        setFormData((prevState) => {
            const updatedAnswers = prevState.answers.filter(ans => ans.question_id !== questionId);
            updatedAnswers.push({ question_id: questionId, answer });
            return {
                ...prevState,
                answers: updatedAnswers
            };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, email, role_id, password, answers } = formData;
        const user_role = role_id;
    
        try {
            const response = await fetch("https://api.biznetusa.com/api/register", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name, email, user_role, password, answers })
            });
    
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.errors?.email || "Something went wrong during registration.");
            }
    
            // Saving the data into local storage
            localStorage.setItem("access_token", data.access_token);
            localStorage.setItem("user_role", data.user_role);
            localStorage.setItem("token_type", data.token_type);
    
            toast.success("Registration successful!");
            navigate("/login", { state: { name } });
        } catch (error) {
            console.error("Error during registration:", error);
            toast.error(error.message || "Failed to register. Please try again.");
        }
    };
    
    return (
        <>
            <main className="background_color_fixed">
                <ToastContainer />
                <div className="d-flex align-items-center justify-content-center min-vh-100">
                    <div className="card body_color shadow-lg p-4" style={{ width: "26rem" }}>
                        <h2 className="h4 fw-bold text-center mb-4">Welcome to Znet</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="role_id" className="form-label">User Role</label>
                                <select
                                    className="form-control"
                                    id="role_id"
                                    value={formData.role_id}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select a role</option>
                                    {roles.map(role => (
                                        <option key={role.id} value={role.id}>{role.role_name}</option>
                                    ))}
                                </select>
                            </div>
                            {questions.map((question) => (
                                <div key={question.id} className="mb-3">
                                    <label htmlFor={`question-${question.id}`} className="form-label">{question.question}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id={`question-${question.id}`}
                                        value={formData.answers.find((ans) => ans.question_id === question.id)?.answer || ""}
                                        onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                                        required
                                    />
                                </div>
                            ))}
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <small className="form-text d-flex flex-column py-3 px-3">
                                <span>At least 8 characters.</span>
                                <span>Mix of letters and numbers.</span>
                                <span>At least 1 special character.</span>
                                <span>At least 1 lowercase letter.</span>
                                <span>At least 1 uppercase letter.</span>
                            </small>
                            <p className="text-center mt-3">
                                By submitting, I accept Znet{" "}
                                <a href="#" className="text-decoration -underline">
                                    terms of use
                                </a>
                                .
                            </p>
                            <button type="submit" className="btn btn-primary w-100">Register</button>
                        </form>

                        <div className="my-4 border-top text-center pt-3">
                            <p>Or connect with:</p>
                            <div className="d-flex justify-content-center flex-column gap-3 mt-2">
                                <button onClick={() => handleSocialLogin(signInWithApple)} className="btn btn-outline-secondary d-flex align-items-center">
                                    <i className="fab fa-apple me-2" />
                                    Continue with Apple
                                </button>
                                <button onClick={() => handleSocialLogin(signInWithFacebook)} className="btn btn-outline-secondary d-flex align-items-center">
                                    <i className="fab fa-facebook-f me-2" />
                                    Continue with Facebook
                                </button>
                                <button onClick={() => handleSocialLogin(signInWithGoogle)} className="btn btn-outline-secondary d-flex align-items-center">
                                    <i className="fab fa-google me-2" />
                                    Continue with Google
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default SignUp;
