import React, { useEffect, useState } from "react";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/Footer";

const SingleBlog = () => {
    const { id } = useParams(); // Get blog ID from URL params
    const [blog, setBlog] = useState(null);
    const [imagePath, setImagePath] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        fetchBlog();
    }, []);

    const fetchBlog = async () => {
        try {
            const response = await fetch(`https://api.biznetusa.com/api/get-blog/${id}`);
            const data = await response.json();
            if (data.status === 200) {
                setBlog(data.blogs);
                setImagePath(data.imagePath);
            }
        } catch (error) {
            console.error("Error fetching blog:", error);
        }
    };

    if (!blog) {
        return <Container>Loading...</Container>;
    }

    return (
        <>
        <Header/>
        <Container className="mt-5">
            <Button variant="secondary" onClick={() => navigate(-1)} className="mb-4">
                Back
            </Button>
            <Card className="border-0">
                {blog.images && blog.images.length > 0 && (
                    <Card.Img
                        variant="top"
                        src={`${imagePath}/${blog.images[0].image}`}
                        alt={blog.title}
                        className="rounded mb-4"
                    />
                )}
                <Card.Body>
                    <Card.Title className="mb-3">{blog.title}</Card.Title>
                    <Card.Text dangerouslySetInnerHTML={{ __html: blog.desc }}></Card.Text>
                    <Row className="mt-4">
                        {blog.images &&
                            blog.images.map((image, index) => (
                                <Col xs={6} md={4} key={index} className="mb-3">
                                    <img
                                        src={`${imagePath}/${image.image}`}
                                        alt={`Blog Image ${index + 1}`}
                                        className="img-fluid rounded"
                                    />
                                </Col>
                            ))}
                    </Row>
                </Card.Body>
            </Card>
        </Container>
        <Footer/>
        </>
    );
};

export default SingleBlog;