
const WhyZnet = () => {
    return (
        <section className="py-5" style={{ backgroundColor: '#f9f9f9' }}>
            <div className="container">
                <h2 className="text-center fw-bold mb-4">Your All-in-One Real Estate Solution</h2>

                <ul className="list-unstyled mb-5">
                    <li className="mb-3 d-flex align-items-start">
                        <span className="me-3">
                            <i className="fa fa-check-circle text-success"></i>
                        </span>
                        <div>
                            <strong>Quick Matches:</strong> Get connected with the right people instantly.
                        </div>
                    </li>
                    <li className="mb-3 d-flex align-items-start">
                        <span className="me-3">
                            <i className="fa fa-check-circle text-success"></i>
                        </span>
                        <div>
                            <strong>Expert Strategies:</strong> Receive personalized advice for your goals.
                        </div>
                    </li>
                    <li className="mb-3 d-flex align-items-start">
                        <span className="me-3">
                            <i className="fa fa-check-circle text-success"></i>
                        </span>
                        <div>
                            <strong>Seamless Transactions:</strong> Communicate and collaborate in one platform.
                        </div>
                    </li>
                </ul>

                <div className="comparison-table mb-5">
                    <table className="table table-bordered text-center">
                        <thead className="table-primary">
                            <tr>
                                <th>Feature</th>
                                <th>ZNet</th>
                                <th>Traditional Networking</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Speed of Connections</td>
                                <td>Instant</td>
                                <td>Slow</td>
                            </tr>
                            <tr>
                                <td>Expert Guidance</td>
                                <td>Personalized</td>
                                <td>Generic</td>
                            </tr>
                            <tr>
                                <td>Communication</td>
                                <td>Integrated Platform</td>
                                <td>Scattered Tools</td>
                            </tr>
                            <tr>
                                <td>Ease of Use</td>
                                <td>Seamless</td>
                                <td>Complicated</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default WhyZnet;
