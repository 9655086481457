import React from "react";
import "../../../assets/css/investors.css"
import ImagewithText from "../../../components/ImagewithText";
import aboutImage1 from "../../../assets/images/Developer.jpg"
import ContactSection from "../../../components/ContactSection";
import FinancialResults from "../../../components/FinancialResults";
import Header from "../../../components/header";
import Footer from "../../../components/Footer";


const Investors = () => {
    return (
        <>
            <Header />
            <main>
                <div className="investor-page">
                    <div className="investor-main d-flex justify-content-center align-items-center">
                        <div className="container">
                            <p>Investor relations</p>
                            <h1 className="w-50 fs-1">
                                Redefining real estate in the consumer’s favor
                            </h1>
                            <p className="w-50">
                                Znet helps people buy and sell homes in over 100 markets across the
                                U.S. and Canada. As a residential real estate brokerage, we combine
                                our agents and technology to create a faster, better and more
                                affordable service.{" "}
                            </p>
                            <div className="btn">
                                <button className=" btn-investor">View presentation</button>
                            </div>
                        </div>
                    </div>
                    <div className="second-section d-flex justify-content-between container my-5">
                        <h1 className="h3 font-weight-bold mb-4">
                            Redefining real estate in the consumer's favor
                        </h1>
                        <button className="btn-investor text-dark">Get Started</button>
                    </div>
                    {/* Latest News Section */}
                    <section className="mt-4">
                        <div className="container">
                            <h2 className="h4 font-weight-bold mb-4">Latest news</h2>
                            <div className="bg-second-section p-3">
                                <p>Investor relations</p>
                                <h1 className="w-50 fs-1">
                                    Redefining real estate in the consumer’s favor
                                </h1>
                                <p className="w-50">
                                    Znet helps people buy and sell homes in over 100 markets across
                                    the U.S. and Canada. As a residential real estate brokerage, we
                                    combine our agents and technology to create a faster, better and
                                    more affordable service.{" "}
                                </p>
                                <div className="btn">
                                    <button className=" btn-investor">Learn more</button>
                                </div>
                            </div>
                            <div className="row g-4 ">
                                <div className="card col-lg-4 h-100 p-4">
                                    <h2 className="h5 mb-3">September 12, 2024 • 8:00 am EDT</h2>
                                    <h6>
                                        Znet Reports Falling Mortgage Rates Mean Housing Payments Are
                                        Now More Affordable Than a Year Ago Despite Higher Prices
                                    </h6>
                                    <a href="#">Learn more</a>
                                </div>
                                <div className="card col-lg-4 h-100 p-4">
                                    <h2 className="h5 mb-3">September 10, 2024 • 8:01 am EDT</h2>
                                    <h6>
                                        Znet Reports Asking Rents Rose the Most in Over a Year in
                                        August, But Remain Below Record Highs Hit Two Years Earlier
                                    </h6>
                                    <a href="#">Learn more</a>
                                </div>
                                <div className="card col-lg-4 h-100 p-4">
                                    <h2 className="h5 mb-3">September 9, 2024 • 8:30 am EDT</h2>
                                    <h6>
                                        New NAR Rules Are a Bargaining Chip, Putting Pressure on
                                        Commissions in Competitive Markets, Are Willing to Cover Some Fees
                                    </h6>
                                    <a href="#">Learn more</a>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Corporation at a Glance Section */}
                    <FinancialResults />
                    {/* corporation at a glance */}
                    <div className="container">
                        <div className="row">
                            <div className=" p-4">
                                <h2 className="text-center mb-4 h3 fw-bold">
                                    Znet corporation at a glance
                                </h2>
                                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                                    <div className="col">
                                        <div className="bg-white p-4 rounded shadow-sm">
                                            <h3 className="h2 fw-semibold">559,000</h3>
                                            <hr className="border-2 border-success my-2" />
                                            <p className="text-muted">
                                                Customers bought or sold homes with us through 2023
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="bg-white p-4 rounded shadow-sm">
                                            <h3 className="h2 fw-semibold">$281B</h3>
                                            <hr className="border-2 border-success my-2" />
                                            <p className="text-muted">
                                                Worth of homes bought or sold by our customers through 2023
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="bg-white p-4 rounded shadow-sm">
                                            <h3 className="h2 fw-semibold">50 million</h3>
                                            <hr className="border-2 border-success my-2" />
                                            <p className="text-muted">
                                                Average monthly visitors to our website and mobile app in 2023
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="bg-white p-4 rounded shadow-sm">
                                            <h3 className="h2 fw-semibold">4,693</h3>
                                            <hr className="border-2 border-success my-2" />
                                            <p className="text-muted">Employees</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="bg-white p-4 rounded shadow-sm">
                                            <h3 className="h2 fw-semibold">1,776</h3>
                                            <hr className="border-2 border-success my-2" />
                                            <p className="text-muted">
                                                Average number of lead agents for 2023
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="bg-white p-4 rounded shadow-sm">
                                            <h3 className="h2 fw-semibold">100 +</h3>
                                            <hr className="border-2 border-success my-2" />
                                            <p className="text-muted">Markets</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Innovative Solutions Section */}
                    <section className="mt-4 container">
                        <div className="row p-4 bg-light rounded-lg shadow-lg">
                            <div className="row align-items-center">
                                <ImagewithText
                                    content="Znet has been at the forefront of real estate, offering unparalleled services to clients. From residential to commercial, we have the expertise and commitment to help you find your perfect space."
                                    imgSrc={aboutImage1}
                                    altText="About Znet"
                                />
                            </div>
                            <div className="d-flex align-items-center justify-content-between p-4 bg-light border-bottom">
                                <div className="d-flex flex-row gap-5">
                                    <h2 className="h4 fw-bold text-dark">Stay up to date</h2>
                                    <p className="text-muted">
                                        Get the latest news and reports delivered right to your inbox.
                                    </p>
                                </div>
                                <button className="btn text-dark  btn-investor text-light px-4 py-2 rounded">
                                    Sign up
                                </button>
                            </div>
                        </div>
                    </section>
                    {/* Contact Section */}
                    <ContactSection />
                </div>
            </main>
            <Footer />


        </>
    )
}
export default Investors
