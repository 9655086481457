import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/Auth.css";
import { signInWithGoogle, signInWithFacebook, signInWithApple } from '../../authMethods';


const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSocialLogin = (loginFunction) => {
        loginFunction()
            .then((user) => {
                console.log("Logged in user:", user);
                alert("Login successful!");
                navigate("/Questionaire"); // Navigate after successful login
            })
            .catch((error) => {
                console.error("Login Error:", error);
                alert(`Login failed: ${error.message}`);
            });
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new URLSearchParams();
        formData.append("email", email);
        formData.append("password", password);

        try {
            const response = await fetch("https://api.biznetusa.com/api/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: formData.toString(),
            });

            const data = await response.json();

            if (!response.ok) throw new Error(data.message || "Failed to log in");

            const { access_token, token_type, user_role, permissions } = data;
            if (!access_token) throw new Error("Invalid response from server.");

            const token = `${token_type} ${access_token}`;
            saveToken(token);
            await fetchUserDetails(token);

            toast.success("Login successful!");
            navigate("/Questionaire"); // Navigate after successful login
        } catch (error) {
            toast.error(error.message || "An unexpected error occurred.");
        } finally {
            setLoading(false);
        }
    };

    const saveToken = (token) => {
        localStorage.setItem("token", token);
    };

    const fetchUserDetails = async (token) => {
        try {
            const response = await fetch("https://api.biznetusa.com/api/user", {
                method: "GET",
                headers: {
                    "Authorization": token,
                },
            });

            if (!response.ok) throw new Error("Failed to fetch user details.");

            const userData = await response.json();
            const { id, email, user_role, permissions } = userData;

            if (!id) throw new Error("User ID is missing in the fetched user details.");

            localStorage.setItem("user_id", id);
            localStorage.setItem("user_email", email);
            localStorage.setItem("roles", user_role);
            localStorage.setItem("permissions", JSON.stringify(permissions));

            navigate("/"); // Redirect to the home page after successful login
        } catch (error) {
            toast.error(error.message || "Failed to fetch user details.");
        }
    };


    return (
        <>
            <main className="background_color_fixed">
                <div className="d-flex align-items-center justify-content-center min-vh-100">
                    <div
                        className="card body_color shadow-lg p-4"
                        style={{ maxWidth: "26rem", width: "100%" }}
                    >
                        <a className="text-decoration-none" href="/">
                            <h2 className="h4 fw-bold py-3 mb-3">Welcome to ZNet</h2>
                        </a>
                        <div className="mb-3 d-flex flex-row gap-5">
                            <a className="text-decoration-none" href="/Login">
                                <h3 className="h6 fw-semibold border-bottom border-2">Sign In</h3>
                            </a>
                            <a className="text-decoration-none" href="/SignUp">
                                <h3 className="h6 fw-semibold border-bottom border-2">
                                    New Account
                                </h3>
                            </a>
                        </div>
                        <form onSubmit={handleLogin}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="Enter email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    disabled={loading}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="password">
                                    Password
                                </label>
                                <input
                                    type="password"
                                    id="password"
                                    placeholder="Enter password"
                                    className="form-control"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    disabled={loading}
                                />
                            </div>
                            <button type="submit" className="btn w-100" disabled={loading}>
                                {loading ? "Signing in..." : "Sign in"}
                            </button>
                            <p className="text-center mt-3">
                                <a href="/ForgotPassword" className="text-decoration-underline">
                                    Forgot your password?
                                </a>
                            </p>
                        </form>
                        <div className="my-4 border-top text-center pt-3">
                            <p>Or connect with:</p>
                            <div className="d-flex justify-content-center flex-column gap-3 mt-2">
                                <button
                                    className="btn btn-outline-secondary d-flex align-items-center"
                                    disabled={loading}
                                    onClick={() => handleSocialLogin(signInWithApple)}
                                >
                                    <i className="fab fa-apple me-2" />
                                    Continue with Apple
                                </button>
                                <button
                                    className="btn btn-outline-secondary d-flex align-items-center"
                                    disabled={loading}
                                    onClick={() => handleSocialLogin(signInWithFacebook)}
                                >
                                    <i className="fab fa-facebook-f me-2" />
                                    Continue with Facebook
                                </button>
                                <button
                                    className="btn btn-outline-secondary d-flex align-items-center"
                                    disabled={loading}
                                    onClick={() => handleSocialLogin(signInWithGoogle)}
                                >
                                    <i className="fab fa-google me-2" />
                                    Continue with Google
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <ToastContainer />
        </>
    );
};

export default Login;
