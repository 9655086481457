import React from "react";
import './ContactSection.css'
import { Link } from "react-router-dom";

const ContactSection = () => {

    return (
        <>
            <section className="mt-4 container">
                <div className="p-4 bg-light rounded shadow-sm">
                    <h2 className="h4 fw-semibold mb-4">Contacts</h2>
                    <div className="row g-4">
                        <div className="col-12 card-CoreValueCard col-md-4">
                            <div className="p-3   h-100 rounded shadow-sm">
                                <h3 className="h5 fw-bold">Company</h3>
                                <p>Znet Corporation</p>
                                <p>1099 Stewart Street</p>
                                <p>Suite 600</p>
                                <p>Seattle, WA 98101</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="p-3 bg-white h-100 rounded shadow-sm">
                                <h3 className="h5 fw-bold">Investor relations</h3>
                                <p>Meg Nunnally</p>
                                <p>Head of Investor Relations</p>
                                <Link to="javascript:void(0)" className="text-primary">
                                    ir@Znet.com
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="p-3 bg-white h-100 rounded shadow-sm">
                                <h3 className="h5 fw-bold">Press</h3>
                                <p>Mariam Sughayer</p>
                                <p>Head of Communications</p>
                                <Link to="javascript:void(0)" className="text-primary">
                                    press@Znet.com
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default ContactSection;
