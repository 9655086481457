import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const WhatsNewSection = () => {
    const [newsItems, setNewsItems] = useState([]);
    const [imagePath, setImagePath] = useState("");
    const navigate = useNavigate();

    const handleReadMore = (id) => {
        navigate(`/blog/${id}`);
    };

    useEffect(() => {
        const fetchLatestBlogs = async () => {
            try {
                const response = await fetch("https://api.biznetusa.com/api/get-latestblog");
                const data = await response.json();
                if (data.status === 200) {
                    setNewsItems(data.blogs);
                    setImagePath(data.imagePath);
                }
            } catch (error) {
                console.error("Error fetching latest blogs:", error);
            }
        };

        fetchLatestBlogs();
    }, []);

    return (
        <Container>
            <h4 className="mb-4">What's New</h4>
            <hr className="mb-4" />
            <Row className="gy-4">
                {newsItems.map((newsItem, index) => (
                    <Col md={12} key={index}>
                        <Card className="border-0 h-100">
                            <Row className="g-0 align-items-center">
                                <Col xs={4}>
                                    <Card.Img
                                        src={
                                            newsItem.images && newsItem.images.length > 0
                                                ? `${imagePath}/${newsItem.images[0].image}`
                                                : "https://via.placeholder.com/150"
                                        }
                                        alt={newsItem.title}
                                        className="rounded"
                                        onClick={() => handleReadMore(newsItem.id)}
                                    />
                                </Col>
                                <Col xs={8}>
                                    <Card.Body>
                                        <Card.Title className="fs-6">
                                            {newsItem.title}
                                        </Card.Title>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default WhatsNewSection;
