import React from "react";

const Testimonial = () => {
    return (
        <section className="py-5 bg-light">
            <div className="container">
                <h2 className="section-title fw-bold h1 text-center mb-4">
                    What Our Clients Say
                </h2>
                <p className="text-center text-muted mb-5">
                    Hear directly from our satisfied clients about their experiences with ZNet.
                    We take pride in making real estate seamless and successful for everyone.
                </p>
                <div
                    id="testimonialCarousel"
                    className="carousel slide position-relative"
                    data-bs-ride="carousel"
                >
                    <div className="carousel-inner" style={{ height: '350px' }}>
                        <div className="carousel-item active" style={{ height: '300px' }}>
                            <div className="row g-3">
                                <div className="h-100 col-md-4 d-flex align-items-stretch">
                                    <div className="card p-4 shadow border-0 w-100 h-100">
                                        <h5 className="fw-bold text-primary mb-3">Outstanding Service</h5>
                                        <p className="testimonial text-muted mb-4">
                                            "ZNet helped us find our dream home with exceptional service. Their professionalism, attention to detail, and dedication were truly outstanding. We couldn’t have asked for a better real estate partner."
                                        </p>
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="author-image rounded-circle bg-primary d-flex justify-content-center align-items-center text-white me-3"
                                                style={{ width: "50px", height: "50px" }}
                                            >
                                                S
                                            </div>
                                            <div>
                                                <h6 className="fw-bold mb-0">Sarah L.</h6>
                                                <small className="text-muted">Home Buyer</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="h-100 col-md-4 d-flex align-items-stretch">
                                    <div className="card p-4 shadow border-0 w-100 h-100">
                                        <h5 className="fw-bold text-secondary mb-3">Smooth & Stress-Free</h5>
                                        <p className="testimonial text-muted mb-4">
                                            "We sold our property in no time, thanks to the expertise of ZNet’s team. Their market knowledge and proactive approach made the process smooth and stress-free. Highly recommend!"
                                        </p>
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="author-image rounded-circle bg-secondary d-flex justify-content-center align-items-center text-white me-3"
                                                style={{ width: "50px", height: "50px" }}
                                            >
                                                M
                                            </div>
                                            <div>
                                                <h6 className="fw-bold mb-0">Mark D.</h6>
                                                <small className="text-muted">Property Seller</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="h-100 col-md-4 d-flex align-items-stretch">
                                    <div className="card p-4 shadow border-0 w-100 h-100">
                                        <h5 className="fw-bold text-success mb-3">Exceeded Expectations</h5>
                                        <p className="testimonial text-muted mb-4">
                                            "ZNet exceeded our expectations in every aspect. From the initial consultation to closing the deal, they were attentive, responsive, and highly professional. Our investment process was seamless."
                                        </p>
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="author-image rounded-circle bg-success d-flex justify-content-center align-items-center text-white me-3"
                                                style={{ width: "50px", height: "50px" }}
                                            >
                                                E
                                            </div>
                                            <div>
                                                <h6 className="fw-bold mb-0">Emily W.</h6>
                                                <small className="text-muted">Investor</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Group of 3 Testimonials (2nd Slide) */}
                        <div className="carousel-item">
                            <div className="row g-3">
                                <div className="h-100 col-md-4 d-flex align-items-stretch">
                                    <div className="card p-4 shadow border-0 w-100 h-100">
                                        <h5 className="fw-bold text-danger mb-3">Fantastic Support</h5>
                                        <p className="testimonial text-muted mb-4">
                                            "The team at ZNet always provided quick and effective support. They truly care about their clients and go above and beyond!"
                                        </p>
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="author-image rounded-circle bg-danger d-flex justify-content-center align-items-center text-white me-3"
                                                style={{ width: "50px", height: "50px" }}
                                            >
                                                F
                                            </div>
                                            <div>
                                                <h6 className="fw-bold mb-0">Fiona G.</h6>
                                                <small className="text-muted">First-Time Buyer</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="h-100 col-md-4 d-flex align-items-stretch">
                                    <div className="card p-4 shadow border-0 w-100 h-100">
                                        <h5 className="fw-bold text-info mb-3">Highly Responsive</h5>
                                        <p className="testimonial text-muted mb-4">
                                            "ZNet's responsiveness and attention to detail made the entire process smooth. They kept us informed every step of the way."
                                        </p>
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="author-image rounded-circle bg-info d-flex justify-content-center align-items-center text-white me-3"
                                                style={{ width: "50px", height: "50px" }}
                                            >
                                                H
                                            </div>
                                            <div>
                                                <h6 className="fw-bold mb-0">Henry K.</h6>
                                                <small className="text-muted">Investor</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="h-100 col-md-4 d-flex align-items-stretch">
                                    <div className="card p-4 shadow border-0 w-100 h-100">
                                        <h5 className="fw-bold text-warning mb-3">Impressive Results</h5>
                                        <p className="testimonial text-muted mb-4">
                                            "We achieved fantastic results with ZNet’s team. Their expertise and dedication were unmatched. Truly a great experience!"
                                        </p>
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="author-image rounded-circle bg-warning d-flex justify-content-center align-items-center text-white me-3"
                                                style={{ width: "50px", height: "50px" }}
                                            >
                                                I
                                            </div>
                                            <div>
                                                <h6 className="fw-bold mb-0">Irene T.</h6>
                                                <small className="text-muted">Property Seller</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Carousel Controls */}
                    <button
                        className="carousel-control-prev position-absolute top-0 translate-middle-y"
                        type="button"
                        data-bs-target="#testimonialCarousel"
                        data-bs-slide="prev"
                    >
                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                        className="carousel-control-next position-absolute top-0 translate-middle-y"
                        type="button"
                        data-bs-target="#testimonialCarousel"
                        data-bs-slide="next"
                    >
                        <span className="carousel-control-next-icon" aria-hidden="true" />
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </section>
    );
};

export default Testimonial;

