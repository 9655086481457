import React from 'react'
import Header from '../../components/header'
import Footer from '../../components/Footer'
import AmericanFlag from '../../assets/images/american_flag.jpg'
import { FaTag } from "react-icons/fa";

const Tags = ({ tag }) => (
    <div className="d-flex align-items-center justify-content-center gap-3 py-3 col-md-4 col-xs-12">
        {tag && (
            <div className="d-flex flex-md-row flex-xs-column text-md-start text-xs-center">
                <div className='d-flex justify-content-center align-items-center px-3'>
                    <div className='border border-1 border-primary p-3 rounded-circle'>
                        <FaTag className="mb-2 text-primary fs-5 " size={40} />
                    </div>
                </div>
                <div className='text-start'>
                    <h2 className="mb-0 text-primary">Median Sale Price</h2>
                    <p className="mb-0 fs-3">$423,313</p>
                    <p className="mb-0">small-over-small</p>
                </div>
            </div>
        )}
    </div>
);

const ArticleSection = () => (
    <div className="col-md-4 col-xs-12 d-flex flex-column gap-2 px-4">
        <h2>Housing Market</h2>
        <img src={AmericanFlag} alt="" />
        <p className='border-bottom border-bottom-2 border-secondary py-3'>2024 Local Election Results Show Voters are Willing to Spend Big on Affordable Housing </p>
        <p className='border-bottom border-bottom-2 border-secondary pb-3'>More Buyers Are Touring Houses, Applying For Mortgages As We Enter 2024’s Home Stretch</p>
        <a className='text-primary text-decoration-none'>See all Reports</a>
    </div>
);

const NewsSection = () => (
    <>
        <h2>What's New</h2>
        <NewsBanner />
        <NewsBanner />
        <NewsBanner />
        <NewsBanner />
        <div className='py-3 border-top border-top-1 border-secondary'>
            <a className='text-primary text-decoration-none fs-5'>See all reports</a>
        </div>
    </>
);

const NewsBanner = () => (
    <div className='d-flex py-3 border-top border-top-1 border-secondary justify-content-between'>
        <NewsCard title="Deck the halls with Znet Designs" date="December 5, 2024" />
        <div className="col-md-4 offset-md-1">
            <img src={AmericanFlag} alt="" className='w-100 m-0 p-0' style={{ height: '80px', objectFit: "contain" }} />
        </div>
    </div>
)

const NewsCard = ({ title, date, description, isDark }) => (
    <div className='d-flex flex-column'>
        <p className='text-dark-subtle p-0 pt-2 m-0 fs-4 lh-sm'>{title}</p>
        <p className={`p-0 ${isDark ? 'text-black' : 'text-muted'}`}>{date}</p>
        {description && <p>{description}</p>}
    </div>
);

const RealEstateNews = () => {
    return (
        <>
            <Header />
            <div className="container-md container-fluid">
                <main className='row'>
                    <div className="news-card col-md-6 col-xs-12">
                        <img src={AmericanFlag} alt="" className='w-100' />
                        <NewsCard isDark={false} title="2024 Local Election Results Show Voters are Willing to Spend Big on Affordable Housing" date="December 5, 2024" />
                    </div>
                    <div className="news-section col-md-6 col-xs-12">
                        <NewsSection />
                    </div>
                </main>
                <section className='border-bottom border-bottom-2 border-secondary py-3'>
                    <NewsCard title={'United States Housing Market'} date={'December 5, 2024'} isDark={true} />
                </section>
                <section className="tags row">
                    <Tags tag={true} />
                    <Tags tag={true} />
                    <Tags tag={true} />
                </section>
                <section className='p-4 py-5 w-100 bg-secondary-subtle'>
                    <div className='row'>
                        <ArticleSection />
                        <ArticleSection />
                        <ArticleSection />
                    </div>
                    <div className='row mt-4'>
                        <ArticleSection />
                        <ArticleSection />
                        <ArticleSection />
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default RealEstateNews;
